import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import menuIcon from 'assets/images/icons/ic_menu.svg';
import closeIcon from 'assets/images/ic-close.svg';
import Logo from '../../../assets/images/logo.svg';
import LogoMobile from '../../../assets/images/logo-mobile.svg';

import { configurations } from '../../../config';
// import SoonItem from './SoonItem';
import './menu.scss';

export default function Menu() {
    const [showMenu, setShowMenu] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const wrapperRef = useRef(null);

    const handleClickOutside = useCallback((wrapperRef) => {
        return (event: any) => {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target)
            ) {
                setShowMenu(false);
            }
        };
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        document.addEventListener('mousedown', handleClickOutside(wrapperRef));

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener(
                'mousedown',
                handleClickOutside(wrapperRef)
            );
        };
    }, [handleClickOutside]);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    const handleScrollToElement = (elementHas: string) => {
        const element = document.querySelector(`.${elementHas}`);
        if (element) {
            const yOffset = -50;
            const y =
                element.getBoundingClientRect().top +
                window.pageYOffset +
                yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    return (
        <div className="menu-header">
            <div
                ref={wrapperRef}
                className={`main-menu ${
                    scrollPosition > 0 ? 'fixed-menu' : ''
                }`}
            >
                <div className="container-menu">
                    <div className="left-menu">
                        {' '}
                        <div>
                            <img
                                src={menuIcon}
                                className="style-menu-logo"
                                alt="logo"
                                onClick={() => setShowMenu(true)}
                            />
                            <Link to="/" className="logo-desktop">
                                <img src={Logo} className="style-logo" alt="" />
                            </Link>
                            <Link to="/" className="logo-mobile">
                                <img
                                    src={LogoMobile}
                                    className="style-logo"
                                    alt=""
                                />
                            </Link>
                        </div>
                        <Link
                            className="btn-primary btn-dashboard"
                            to="/dashboard"
                        >
                            Launch Dashboard
                        </Link>
                    </div>
                    <div className="right-menu">
                        <div
                            className={`menu-list ${
                                showMenu ? 'show-menu-mobile' : ''
                            }`}
                        >
                            <div className="header-mobile">
                                <Link to="/">
                                    <img
                                        src={Logo}
                                        className="style-logo"
                                        alt=""
                                    />
                                </Link>
                                <img
                                    src={closeIcon}
                                    alt="icon"
                                    onClick={() => setShowMenu(false)}
                                />
                            </div>

                            <ul>
                                {/* <li className="item-menu">
                                   

                                    <NavLink
                                        activeClassName="item--active"
                                        className="link-item"
                                        to="/markets"
                                    >
                                        NFT Market
                                    </NavLink>
                                </li> */}
                                {/* <li className="item-menu">
                                <NavLink
                                    activeClassName="item--active"
                                    className="link-item"
                                    to="/fusion"
                                >
                                    Super Heroes Fusion
                                </NavLink>
                            </li> */}
                                {/* <li className="item-menu">
                                    <NavLink
                                        activeClassName="item--active"
                                        className="link-item"
                                        to="/battlefields"
                                    >
                                      
                                        Meme Battle
                                    </NavLink>
                                </li> */}

                                {/* <li className="item-menu">
                                    <a
                                        target="_blank"
                                        className="link-item"
                                        href={`https://play.tinyhero.io`}
                                        rel="noopener noreferrer"
                                    >
                                      
                                        Meme Play
                                    </a>
                                </li> */}
                                <li className="item-menu">
                                    <a
                                        target="_blank"
                                        className="link-item"
                                        href={`https://polygonscan.com/address/${configurations.tinyAddress}`}
                                        rel="noopener noreferrer"
                                    >
                                        Contract
                                    </a>
                                </li>
                                <li
                                    className="item-menu"
                                    onClick={() =>
                                        handleScrollToElement('great-features')
                                    }
                                >
                                    <a
                                        onClick={(event) =>
                                            event.preventDefault()
                                        }
                                        className="link-item"
                                        href="/"
                                        rel="noopener noreferrer"
                                    >
                                        Features
                                    </a>
                                </li>
                                <li className="item-menu">
                                    <a
                                        className="link-item"
                                        href="https://docs.tinyhero.io/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Document
                                    </a>
                                </li>
                                <li
                                    className="item-menu"
                                    onClick={() =>
                                        handleScrollToElement('our-roadmap')
                                    }
                                >
                                    <a
                                        onClick={(event) =>
                                            event.preventDefault()
                                        }
                                        className="link-item"
                                        href="/"
                                        rel="noopener noreferrer"
                                    >
                                        Roadmap
                                    </a>
                                </li>
                                <li className="item-menu">
                                    <Link
                                        to="/dashboard"
                                        className="btn-primary"
                                    >
                                        Launch Dashboard
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className={`${showMenu ? 'overlay' : ''}`}></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
