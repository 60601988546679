import { AbiItem } from 'web3-utils';

export const abis = {
    ERC20Abi: require('./abi/erc20.json') as AbiItem[],
    TDOAbi: require('./abi/tdo.json') as AbiItem[],
    EggNFTSaleAbi: require('./abi/egg_nft_sale.json') as AbiItem[],
    TinyNFTSaleAbi: require('./abi/tiny_nft_sale.json') as AbiItem[],
    EggNFTAbi: require('./abi/egg_nft.json') as AbiItem[],
    TinyNFTAbi: require('./abi/tiny_nft.json') as AbiItem[],
    EggEvolutionAbi: require('./abi/egg_evolution.json') as AbiItem[],
    TinyFactoryAbi: require('./abi/tiny_factory.json') as AbiItem[],
    FusionAbi: require('./abi/fusion.json') as AbiItem[],
    SuperTinyStatsAbi: require('./abi/super_tiny_stats.json') as AbiItem[],
    BattleAbi: require('./abi/battle.json') as AbiItem[],
    RewardLockAbi: require('./abi/reward_lock.json') as AbiItem[],
};
