import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';

import { Config } from './type';
import { currentConfig } from './configs';
import { abis } from './abis';

export class Contracts {
    private config: Config;
    public tokens: any = {};
    public TINY: Contract;
    public PAY: Contract;
    public EggNFTSale: Contract;
    public TinyNFTSale: Contract;
    public EggNFT: Contract;
    public TinyNFT: Contract;
    public SuperTinyNFT: Contract;
    public EggEvolution: Contract;
    public TinyFactory: Contract;
    public Fusion: Contract;
    public SuperTinyStats: Contract;
    public Battle: Contract;
    public RewardLock: Contract;

    constructor(provider: any, public web3: Web3) {
        this.config = currentConfig;
        this.TINY = new this.web3.eth.Contract(abis.ERC20Abi);

        const payAbi =
            currentConfig.PAY === 'TDO' ? abis.TDOAbi : abis.ERC20Abi;
        this.PAY = new this.web3.eth.Contract(payAbi);

        this.EggNFTSale = new this.web3.eth.Contract(abis.EggNFTSaleAbi);
        this.TinyNFTSale = new this.web3.eth.Contract(abis.TinyNFTSaleAbi);
        this.EggNFT = new this.web3.eth.Contract(abis.EggNFTAbi);
        this.TinyNFT = new this.web3.eth.Contract(abis.TinyNFTAbi);
        this.EggEvolution = new this.web3.eth.Contract(abis.EggEvolutionAbi);
        this.TinyFactory = new this.web3.eth.Contract(abis.TinyFactoryAbi);
        this.Fusion = new this.web3.eth.Contract(abis.FusionAbi);
        this.SuperTinyNFT = new this.web3.eth.Contract(abis.TinyNFTAbi); //super tiny same as tiny
        this.SuperTinyStats = new this.web3.eth.Contract(
            abis.SuperTinyStatsAbi
        );
        this.Battle = new this.web3.eth.Contract(abis.BattleAbi);
        this.RewardLock = new this.web3.eth.Contract(abis.RewardLockAbi);

        this.setProvider(provider);
    }

    setProvider(provider: any) {
        const setProvider = (contract: any, address: string) => {
            contract.setProvider(provider);
            if (address) {
                contract.options.address = address;
            } else console.error('Contract address not found in network');
        };

        setProvider(this.TINY, this.config.TINY);
        setProvider(this.PAY, this.config.contractAddresses.TokenPay);
        setProvider(this.EggNFTSale, this.config.contractAddresses.EggNFTSale);
        setProvider(
            this.TinyNFTSale,
            this.config.contractAddresses.TinyNFTSale
        );
        setProvider(this.EggNFT, this.config.contractAddresses.EggNFT);
        setProvider(this.TinyNFT, this.config.contractAddresses.TinyNFT);
        setProvider(
            this.SuperTinyNFT,
            this.config.contractAddresses.SuperTinyNFT
        );
        setProvider(
            this.EggEvolution,
            this.config.contractAddresses.EggEvolution
        );
        setProvider(
            this.TinyFactory,
            this.config.contractAddresses.TinyFactory
        );
        setProvider(this.Fusion, this.config.contractAddresses.Fusion);
        setProvider(
            this.SuperTinyStats,
            this.config.contractAddresses.SuperTinyStats
        );
        setProvider(this.Battle, this.config.contractAddresses.Battle);
        setProvider(this.RewardLock, this.config.contractAddresses.RewardLock);
    }

    setDefaultAccount(account: string) {
        this.TINY.options.from = account;
        this.PAY.options.from = account;
        this.Fusion.options.from = account;
    }
}
