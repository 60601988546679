import { FC, useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useWallet } from 'use-wallet';

import './LogoutModal.scss';
import ExportIcon from '../../../assets/images/icons/export.png';
import CopyIcon from '../../../assets/images/icons/copy.png';

type Props = {
    defaultVisible: boolean;
    onCloseModal: any;
};

const LogoutModal: FC<Props> = ({ defaultVisible, onCloseModal }) => {
    const [visible, setVisible] = useState(false);

    const { account, reset } = useWallet();

    useEffect(() => {
        setVisible(defaultVisible);
    }, [defaultVisible]);

    const handleCloseModal = () => {
        setVisible(false);
        onCloseModal();
    };

    const handleLogout = () => {
        reset();
        handleCloseModal();
        localStorage.setItem('signOut', 'true');
        setTimeout(() => {
            window.location.reload();
        }, 500);
    };

    return (
        <Modal
            wrapClassName="logout-modal"
            title="My Wallet"
            visible={visible}
            onCancel={handleCloseModal}
            footer={[
                <button key="logout" onClick={handleLogout} className="logout">
                    Disconnect Wallet
                </button>,
            ]}
        >
            <div className="content">
                <div className="title">Connected with MetaMask</div>
                <div className="address">{account}</div>
                <div className="actions">
                    <div
                        className="copy"
                        onClick={() =>
                            navigator.clipboard.writeText(account || '')
                        }
                    >
                        <img src={CopyIcon} alt="" />
                        Copy Address
                    </div>
                    <div className="scan">
                        <a
                            href={`https://polygonscan.com/address/${account}/transactions`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={ExportIcon} alt="" />
                            View on PolygonScan
                        </a>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default LogoutModal;
