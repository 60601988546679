import React, { useCallback, useEffect, useRef, useState } from 'react';
import MenuDash from './component/MenuDash';
import SearchHeader from './component/SearchHeader';
import './dashboard.scss';
import './filter.scss';
import './card.scss';

export const LayoutTiny: React.FC = ({ children, ...rest }: any) => {
    const [menuShow, setMenuShow] = useState(false);
    const wrapperRef = useRef(null);

    const handleClickOutside = useCallback((wrapperRef) => {
        return (event: any) => {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target)
            ) {
                setMenuShow(false);
            }
        };
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside(wrapperRef));

        return () => {
            window.removeEventListener(
                'mousedown',
                handleClickOutside(wrapperRef)
            );
        };
    }, [handleClickOutside]);

    const handleCloseMenu = () => {
        setMenuShow(false);
    };

    return (
        <div className="home-dashboard">
            {menuShow && <div className="bg-after-menu" />}
            <div
                className={`menu-left-style ${menuShow ? 'show-menu' : ''}`}
                ref={wrapperRef}
            >
                <MenuDash onCloseMenu={handleCloseMenu} />
            </div>
            <div className="body-main-dashboard">
                <SearchHeader
                    menuShow={menuShow}
                    changeMenuShow={(value: boolean) => setMenuShow(!value)}
                />

                {children}
            </div>
        </div>
    );
};
