export const configurations = {
    tinyAddress: '0xB5B9601b62988C8348B10D81e02FA8400Ed55983',
    usdcAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    PoloDividenAddress: '0x3f04F8dEc53eAd6EC63279B138453bb7CFb7d701',
    LOTTERY_API: 'https://api.v3s.finance/api',
};

export const tokennomics = [
    {
        name: 'IDO: <b>400,000,000 (40%)</b>',
        y: 40,
        color: '#4BFFA0',
    },
    {
        name: 'Initial Liquidity: <b>200,000,000 (20%)</b>',
        y: 20,
        color: '#FF4B61',
    },
    {
        name: "<span class = 'item-name'>Game Treasury:</span> <span class='item-content'><b>200,000,000 (20%)</b> <span style='color: rgba(255, 255, 255, 0.60);'>(vesting in 1 year)</span></span> ",
        y: 20,
        color: '#0081F9',
    },

    {
        name: "<span class = 'item-name'>Dev Fund:</span> <span class='item-content'> <b>100,000,000 (10%)</b> <span style='color: rgba(255, 255, 255, 0.60);'>(vesting in 1 year)</span></span>",
        y: 10,
        color: '#AB78FF',
    },
    {
        name: "<span class = 'item-name'>MKT & Advisor:</span> <span class='item-content'> <b>100,000,000 (10%)</b> <span style='color: rgba(255, 255, 255, 0.60);'>(vesting in 1 year)</span></span>",
        y: 10,
        color: '#FFD336',
    },
];

export const options_tokennomincs = {
    chart: {
        type: 'pie',
        backgroundColor: 'transparent',
    },
    title: {
        text: '',
    },

    plotOptions: {
        series: {
            borderWidth: 0,
            dataLabels: {
                enabled: false,
            },
        },
        pie: {
            size: '100%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.y}%</b>',
                distance: -50,
                style: {
                    color: '#ffffff',
                    textOutline: '0px contrast',
                    strokeWidth: 0,
                    fontWeight: 500,
                    fontFamily: '"Poppins", sans-serif',
                    fontSize: 14,
                },
            },
        },
    },

    series: [
        {
            colorByPoint: true,
            data: tokennomics,
            borderColor: '#ffffff',
            borderWidth: '2',
        },
    ],
};
