import { Config } from './type';

export const configs: Record<string, Config> = {
    development: {
        chainId: 137,
        contractAddresses: {
            TokenLottery: '0xB5B9601b62988C8348B10D81e02FA8400Ed55983',
            TokenPay: '0xB5B9601b62988C8348B10D81e02FA8400Ed55983',
            TinyNFTSale: '0xC2ED42cB7e492E87698F61d96942d48486CbA040',
            EggNFTSale: '0x7eFC53fdD785ce834ada2Fa9cB8C712AaA8eA323',
            TinyNFT: '0x5837962CEC0b8A619406C3B9F7D6154406bD802e',
            SuperTinyNFT: '0xbdd84fC4f08060BEb12E9753fa2a942FC90E8a8E',
            EggNFT: '0xA722AD7590a9A72E780faB7F74ca7358F34BDEe1',
            EggEvolution: '0x97cA4743859Ec255a80377bfC337a60359BF0efF',
            TinyFactory: '0xc9feB79D980b97616cd0DbE24f1cf2D5951A89a2',
            Fusion: '0x95BD94061E612480FbFCC0B737F23d7ffECd309d',
            SuperTinyStats: '0xb2e819ca50a2f0b28aa630a2c5bfe313bf7ffd7f',
            Battle: '0xA0b5050736Fa6295ebD8E83E07bf328CD1EDf7A0',
            RewardLock: '0x39B9C964B2a2ebD0459de8138AC4b6789dB20365',
            Lottery: '0x71faa7439c03ee56f3265afc3689b9f69c23be82',
        },
        TINY: '0xB5B9601b62988C8348B10D81e02FA8400Ed55983',
        MATIC: '0x0000000000000000000000000000000000001010',
        PAY: 'TINY',
        explorer: 'https://polygonscan.com',
        explorerName: 'PolygonScan',
        defaultProvider: 'https://polygon-rpc.com/',
        apiUrl: 'https://stg-api.tinyhero.io',
        fusionFee: 1,
        defusionFee: 5,
        subgraphBattle:
            'https://api.thegraph.com/subgraphs/name/tinyhero-io/history-game',
    },
    production: {
        chainId: 137,
        contractAddresses: {
            TokenPay: '0xB5B9601b62988C8348B10D81e02FA8400Ed55983',
            TinyNFTSale: '0x74B7D0555BddDADc2CF9065204ae912d5cea5838',
            EggNFTSale: '0x044d97B5867356f4EB7e821f8756663fc30c907F',
            TinyNFT: '0x5837962CEC0b8A619406C3B9F7D6154406bD802e',
            SuperTinyNFT: '0xFA5a4697B6834b77dBe8471E8f14874281bBfeaA',
            EggNFT: '0xadaB99F2650d63B01f2d4c93D99b6299354eDb06',
            EggEvolution: '0xE947a0507f6cD7884B48463Caa29d98122F2f201',
            TinyFactory: '0x0021De062ecba3CCEfE99bFEf0ed612702f5656c',
            Fusion: '0x276D71749430cd067278039eB46733f42BA5923A',
            SuperTinyStats: '0x70Dbe4bc4C5353a7DBE792Cb286F2a9C5b80601B',
            Battle: '0x8B87d9166D0A6EFd82C18c965991F92bf0A0d30B',
            RewardLock: '0x4c4c8334aa1632361BAe8044D4a43e619923985d',
            TokenLottery: '0xB5B9601b62988C8348B10D81e02FA8400Ed55983',
        },
        TINY: '0xB5B9601b62988C8348B10D81e02FA8400Ed55983',
        MATIC: '0x0000000000000000000000000000000000001010',
        PAY: 'TINY',
        explorer: 'https://polygonscan.com',
        explorerName: 'PolygonScan',
        defaultProvider: 'https://polygon-rpc.com/',
        apiUrl: 'https://api.tinyhero.io',
        fusionFee: 5000,
        defusionFee: 10000,
        subgraphBattle:
            'https://api.thegraph.com/subgraphs/name/tinyhero-io/history-game-prod',
    },
};

export const currentConfig =
    configs[process.env.REACT_APP_ENV || 'development'] || {};
