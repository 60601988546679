import './soonItem.scss';

export default function SoonItem(props: any) {
    const title = props?.title;
    const right = props?.right;
    const top = props?.top;
    return (
        <>
            <div style={{ right: right, top: top }} className="soon-text">
                {title}
            </div>
        </>
    );
}
