import iconBackHeader from 'assets/images/battleFields/icon-back-header.svg';
import iconBattleLarge from 'assets/images/battleFields/icon-battle-large.svg';
import './headerBack.scss';
import { useHistory } from 'react-router-dom';
import { useWallet } from 'use-wallet';
import LogoutModal from '../CModal/LogoutModal';
import { useState } from 'react';

export default function HeaderBack() {
    const history = useHistory();
    const { account, connect } = useWallet();
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    let accountText = 'Connect Wallet';
    if (account) {
        accountText =
            account.slice(0, 4) +
            '...' +
            account.slice(account.length - 4, account.length);
    }

    const handleLogin = () => {
        connect('injected');
        localStorage.removeItem('signOut');
    };

    const handleShowLogoutModal = () => {
        setShowLogoutModal(true);
    };

    const handleCloseLogoutModal = () => {
        setShowLogoutModal(false);
    };

    return (
        <div className="content-header-page">
            <div className="row-header-1">
                <div className="left-header">
                    <div className="icon-back" onClick={() => history.goBack()}>
                        <img
                            className="style-image-back"
                            src={iconBackHeader}
                            alt=""
                        />
                    </div>
                    <div className="icon-logo">
                        <img
                            className="style-image-logo"
                            src={iconBattleLarge}
                            alt=""
                        />
                    </div>
                    <a href="/dashboard" className="title-back">
                        Battlefields
                    </a>
                </div>
                <div className="right-header">
                    {account ? (
                        <>
                            <button
                                className="btn-connect-wallet-bat"
                                onClick={handleShowLogoutModal}
                            >
                                <div
                                    style={{ width: '100%' }}
                                    className="text-shadow"
                                    data-title={accountText}
                                >
                                    {accountText}
                                </div>
                            </button>
                            <LogoutModal
                                defaultVisible={showLogoutModal}
                                onCloseModal={handleCloseLogoutModal}
                            />
                        </>
                    ) : (
                        <button
                            className="btn-connect-wallet-bat"
                            onClick={handleLogin}
                        >
                            <div
                                className="text-shadow"
                                data-title={accountText}
                            ></div>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
