import _ from 'lodash';
import { createContext, useState } from 'react';

interface filterType {
    type: string;
    typeTab: string;
    Rarity: string;
    Status: string;
    sort_order: string;
    sort_by: string;
    order_by: string;
}

type ContextProps = {
    filters: filterType;
    handleChangleFilter: (filterKey: string, filterValue: any) => void;
    totalProduct: number;
    setTotalProduct: (total: number) => void;
};
const pathname = window.location.pathname;

const defaultValue = {
    filters: {
        type: '',
        Status: '1',
        sort_order: 'Price-asc',
        sort_by: 'Price',
        order_by: 'asc',
        typeTab: pathname === '/tiny-hero' ? 'hero' : 'egg',
        Rarity: '',
    },
    handleChangleFilter: (filterKey: string, filterValue: any) => {},
    totalProduct: 0,
    setTotalProduct: (total: number) => {},
};

export const MarketContext = createContext<ContextProps>(defaultValue);

export const MarketContextProvider: React.FC = ({ children }) => {
    const [filters, setFilter] = useState(defaultValue.filters);
    const [totalProduct, setTotalProduct] = useState(defaultValue.totalProduct);

    const handleChangleFilter = (key: string, value: any) => {
        switch (key) {
            case 'sort_order': {
                let [sort_by, order_by] = value.split('-');

                setFilter({
                    ...filters,
                    sort_order: value,
                    sort_by,
                    order_by,
                });

                break;
            }

            case 'typeTab': {
                let filter = {
                    ...filters,
                    [key]: _.isArray(value) ? value.join(',') : value,
                };
                if (value === 'egg') {
                    filter.sort_order = 'Price-asc';
                    filter.sort_by = 'Price';
                    filter.order_by = 'asc';
                }

                setFilter(filter);

                break;
            }

            default: {
                setFilter({
                    ...filters,
                    [key]: _.isArray(value) ? value.join(',') : value,
                });
            }
        }
    };

    const value = {
        filters,
        handleChangleFilter,
        totalProduct,
        setTotalProduct,
        // isReset,
    };

    return (
        <MarketContext.Provider value={value}>
            {children}
        </MarketContext.Provider>
    );
};
