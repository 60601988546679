import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { UseWalletProvider } from 'use-wallet';
import { Web3ReactProvider } from '@web3-react/core';
import 'antd/dist/antd.css';
import './assets/fonts/fonts.scss';
import './assets/scss/index.scss';

import Menu from 'views/Common/Menu/Menu';
import FooterPage from './views/Common/FooterPage/FooterPage';
import TinyProvider from './contexts/TinyProvider';
import { ModalContextProvider } from 'contexts/ModalContext';
import { MarketContextProvider } from 'contexts/MarketContext';
import { LayoutTiny } from 'views/Common/LayoutTiny/LayoutTiny';
import Loading from 'views/Common/Loading';
import ScrollToTop from 'views/Common/ScrollToTop';
import { YourTinyContextProvider } from 'contexts/TinyContext';
import LayoutBackHeader from 'views/Common/HeaderBack/LayoutBackHeader';
import { FANTOM_ID } from 'utils/common';
import { getLibrary } from 'utils/provider';
import { LotteryContextProvider } from 'contexts/LotteryContext';
// import V3SFinanceProvider from 'contexts/V3SFinanceProvider';

const Home = lazy(() => import('./views/Home/Home'));
const Lottery = lazy(() => import('./views/Lottery'));
const Dashboard = lazy(() => import('./views/Dashboard/Dashboard'));
const WhitePaper = lazy(() => import('./views/Whitepaper/WhitePaper'));
const NftMarket = lazy(() => import('./views/NftMarket'));
const NftDetail = lazy(() => import('./views/NftDetail'));
const TinyHero = lazy(() => import('./views/TinyHero'));
const FusionHero = lazy(() => import('./views/Fusion'));
const BattleFields = lazy(() => import('./views/BattleFields'));
const NftSuperHeroDetail = lazy(() => import('./views/NftSuperHeroDetail'));

function App() {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <Providers>
                <Suspense fallback={<Loading />}>
                    <ModalContextProvider>
                        <LotteryContextProvider>
                            <MarketContextProvider>
                                <YourTinyContextProvider>
                                    {/* <V3SFinanceProvider> */}
                                    <Router>
                                        <ScrollToTop />
                                        <Switch>
                                            <Route
                                                path="/whitepaper"
                                                exact
                                                component={WhitePaper}
                                            />
                                            {/* <Route
                                        path="/dashboard"
                                        exact
                                        component={Dashboard}
                                    /> */}
                                            <Route
                                                path="/dashboard"
                                                component={Dashboard}
                                            />
                                            <Route
                                                path="/lottery"
                                                component={Lottery}
                                            />
                                            <Layout
                                                path="/"
                                                exact
                                                component={Home}
                                            ></Layout>
                                            <TinyLayoutRoute
                                                path="/markets"
                                                exact
                                                component={NftMarket}
                                            />
                                            <TinyLayoutRoute
                                                path="/markets/:type/:id"
                                                exact
                                                component={NftDetail}
                                            />
                                            <TinyLayoutRoute
                                                path="/my-nfts/:type/:id"
                                                exact
                                                component={NftDetail}
                                            />
                                            <TinyLayoutRoute
                                                path="/tiny-hero"
                                                exact
                                                component={TinyHero}
                                            />
                                            <TinyLayoutRoute
                                                path="/heroes/:id"
                                                exact
                                                component={NftDetail}
                                            />
                                            <TinyLayoutRoute
                                                path="/fusion"
                                                exact
                                                component={FusionHero}
                                            />
                                            <TinyBackHeaderRoute
                                                path="/battlefields"
                                                exact
                                                component={BattleFields}
                                            />
                                            <TinyBackHeaderRoute
                                                path="/battlefields/:id"
                                                exact
                                                component={BattleFields}
                                            />
                                            <TinyLayoutRoute
                                                path="/super-hero/:id"
                                                exact
                                                component={NftSuperHeroDetail}
                                            ></TinyLayoutRoute>
                                        </Switch>
                                    </Router>
                                    {/* </V3SFinanceProvider> */}
                                </YourTinyContextProvider>
                            </MarketContextProvider>
                        </LotteryContextProvider>
                    </ModalContextProvider>
                </Suspense>
            </Providers>
        </Web3ReactProvider>
    );
}

const Providers: React.FC = ({ children }) => {
    return (
        <UseWalletProvider chainId={FANTOM_ID}>
            <TinyProvider>{children}</TinyProvider>
        </UseWalletProvider>
    );
};

export const Layout = ({ component: Component, ...rest }: any) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <>
                    <Menu />
                    <Component {...props} />
                    <FooterPage />
                </>
            )}
        />
    );
};
export const TinyLayoutRoute = ({ component: Component, ...rest }: any) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <LayoutTiny>
                    <Component {...props} />
                </LayoutTiny>
            )}
        />
    );
};

export const TinyBackHeaderRoute = ({ component: Component, ...rest }: any) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <LayoutBackHeader>
                    <Component {...props} />
                </LayoutBackHeader>
            )}
        />
    );
};

export default App;
