/* eslint-disable react-hooks/exhaustive-deps */
import menuIcon from 'assets/images/icons/ic_menu.svg';
import { useEffect, useState } from 'react';
import { useWallet } from 'use-wallet';
import LogoutModal from 'views/Common/CModal/LogoutModal';
import { openNotificationWithIcon } from 'views/Common/CNotification';
import './searchHeader.scss';
import LogoMenu from 'assets/images/logo.svg';

export default function SearchHeader({
    // changeInput,
    changeMenuShow,
    menuShow,
}: any) {
    const { account, connect, status, error } = useWallet();
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    useEffect(() => {
        if (status === 'error' && !error) {
            openNotificationWithIcon(
                'error',
                4,
                'Switch network',
                'Please connect wallet to the Matic network!'
            );
        }
    }, [status, error]);

    const handleShowLogoutModal = () => {
        setShowLogoutModal(true);
    };

    const handleCloseLogoutModal = () => {
        setShowLogoutModal(false);
    };

    const handleLogin = () => {
        connect('injected');
        localStorage.removeItem('signOut');
    };

    return (
        <div className="search-header-main">
            <div className="container-search-header">
                <div className="box-search">
                    <div className="item-header d-flex">
                        <div
                            className="icon-show-menu-mobile"
                            onClick={() => changeMenuShow(menuShow)}
                        >
                            <img src={menuIcon} alt="icon" />
                        </div>
                        <a href="/">
                            <img
                                src={LogoMenu}
                                className="style-menu-logo"
                                alt="logo"
                            />
                        </a>
                    </div>
                    <div className="item-header">
                        {/* <div className="row-input-search"> */}
                        {/* <CopyOutlined /> */}
                        {/* <input
                                onBlur={(event) =>
                                    setInputAddress(event.target.value.trim())
                                }
                                onChange={handleInputChange}
                                placeholder="Paste your address here"
                                className="style-input-search"
                                value={inputAddress}
                            /> */}
                        {/* </div> */}
                    </div>
                    <div className="item-header">
                        <div className="connect-wallet">
                            {account ? (
                                <>
                                    <button onClick={handleShowLogoutModal}>
                                        {account.slice(0, 4) +
                                            '...' +
                                            account.slice(
                                                account.length - 4,
                                                account.length
                                            )}
                                    </button>
                                    <LogoutModal
                                        defaultVisible={showLogoutModal}
                                        onCloseModal={handleCloseLogoutModal}
                                    />
                                </>
                            ) : (
                                <button onClick={handleLogin}>
                                    Connect wallet
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
