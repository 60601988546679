import LogoMenu from 'assets/images/logo.svg';
import iconBattleFields from 'assets/images/menu/Icon_battle_fields.svg';
import iconUpdown from 'assets/images/menu/icon-updown.png';
// import iconHeroEarning from 'assets/menu/Icon_hero_earning.svg';
import iconEarning from 'assets/images/menu/Icon_earning.svg';
import iconFusion from 'assets/images/menu/Icon_fusion.svg';
import iconHome from 'assets/images/menu/Icon_home.svg';
import iconPolydex from 'assets/images/menu/Icon_polydex.svg';
import iconRead from 'assets/images/menu/Icon_read.svg';
import iconTiny from 'assets/images/menu/Icon_tiny.svg';
import { FC } from 'react';
import { Link, NavLink } from 'react-router-dom';
import SoonItem from '../../Menu/SoonItem';
import './menuDash.scss';

const MenuDash: FC<{ onCloseMenu: any }> = ({ onCloseMenu }) => {
    const LINKS = [
        {
            name: 'Tiny Markets',
            url: '/markets',
            external: false,
            icon: iconHome,
            soon: false,
        },
        {
            name: 'Your Tiny Heroes',
            url: '/tiny-hero',
            external: false,
            icon: iconTiny,
            soon: false,
        },
        {
            name: 'Super Heroes Fusion',
            url: '/fusion',
            external: false,
            icon: iconFusion,
            soon: false,
        },
        {
            name: 'Battlefields',
            url: '/battlefields',
            external: false,
            icon: iconBattleFields,
            soon: true,
            txt: 'New',
        },

        {
            name: 'TINY Earnings',
            url: '/dashboard',
            external: false,
            icon: iconEarning,
            soon: false,
        },
        {
            name: 'TINY Play',
            url: 'https://play.tinyhero.io',
            external: true,
            icon: iconEarning,
        },
        // {
        //     name: 'Hero Earnings',
        //     url: '/#',
        //     external: false,
        //     icon: iconHeroEarning,
        // },
        {
            name: 'PolyDex Store',
            url: 'https://www.polydex.fi/polystore',
            external: true,
            icon: iconPolydex,
        },
        {
            name: 'Read More',
            url: 'https://docs.tinyhero.io/tiny-hero-game/tinyhero-gameplay',
            external: true,
            icon: iconRead,
        },
        // {
        //     name: 'HERO Earnings',
        //     url: '/',
        // },
    ];
    return (
        <div className="box-menu-left-dash">
            <div className="container-box-menu">
                <div className="header-menu-logo">
                    <Link to="/">
                        <img
                            src={LogoMenu}
                            className="style-menu-logo"
                            alt=""
                        />
                    </Link>
                </div>
                <div className="list-menu-left">
                    <ul className="menu-list">
                        {LINKS.map((item, index) => {
                            return (
                                <li key={index}>
                                    {!item.external ? (
                                        <NavLink
                                            to={item.url}
                                            className={`item-menu`}
                                            activeClassName="active-menu"
                                            onClick={onCloseMenu}
                                        >
                                            <span className="item-menu__logo">
                                                <img
                                                    src={item.icon}
                                                    alt="logo"
                                                />
                                            </span>
                                            <div
                                                style={{
                                                    width: 'fit-content',
                                                    position: 'relative',
                                                }}
                                            >
                                                {item.name}
                                                {item?.soon && (
                                                    <SoonItem
                                                        right={-30}
                                                        top={-10}
                                                        title={item.txt}
                                                    />
                                                )}
                                            </div>
                                        </NavLink>
                                    ) : (
                                        <a
                                            className="item-menu"
                                            href={item.url}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <span className="item-menu__logo">
                                                <img
                                                    src={item.icon}
                                                    alt="logo"
                                                />
                                            </span>
                                            <div
                                                style={{
                                                    width: 'fit-content',
                                                    position: 'relative',
                                                }}
                                            >
                                                {item.name}
                                                {item?.soon && (
                                                    <SoonItem
                                                        right={-30}
                                                        top={-10}
                                                        title={item.txt}
                                                    />
                                                )}
                                            </div>
                                        </a>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};
export default MenuDash;
